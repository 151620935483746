'use client'
import Link from 'next/link';
//import blukai, removida

export default function LinkBluKai({ children, llave, valor, ...rest }) {
  return (
    <Link
      onClick={() => {
        //function blukai, removida
      }}
      {...rest}
    >
      {children}
    </Link>
  );
}
