import React, { useState } from 'react';
import styles from './Button.module.css';

const Button = ({
  color,
  size = 'medium',
  children,
  autoWidth = false,
  onClick,
  type = 'button',
  appareance = 'primary',
  disabled = false,
  customStyles = {},
}) => {

  const colors = {
    red: '#DA291C',
    gray: '#95a5a6',
    green: '#2ecc71',
  };

  const primaryButtonStyles = {
    fontSize: size === 'small' ? '14px' : size === 'medium' ? '16px' : '18px',
    color: '#fff',
    height: size === 'small' ? '30px' : size === 'medium' ? '40px' : '50px',
    padding: size === 'small' ? '6px 15px' : size === 'medium' ? '10px 20px' : '12px 32px',
    backgroundColor: color ? colors[color] : colors['gray'],
    width: autoWidth ? '100%' : ''
  };

  const ghostButtonStyles = {
    fontSize: size === 'small' ? '14px' : size === 'medium' ? '16px' : '18px',
    color: color ? colors[color] : '#353535',
    height: size === 'small' ? '30px' : size === 'medium' ? '40px' : '50px',
    padding: size === 'small' ? '6px 15px' : size === 'medium' ? '10px 20px' : '12px 32px',
    backgroundColor: 'transparent',
    border: '1px solid #353535',
    borderColor: color ? colors[color] : '#353535',
    width: autoWidth ? '100%' : ''
  };

  const buttonStyles = appareance === 'primary' ? primaryButtonStyles : ghostButtonStyles;

  return (
    <button type={type} className={styles.Button} style={{...buttonStyles, ...customStyles}} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

export default Button;
